const loadCounter = async () => {
    console.log('Loading counter...')
    document.getElementById('loadCounter')!.setAttribute('disabled', 'disabled')
    const counterElement = document.getElementById('currentCount')!
    const traceElement = document.getElementById('traceId')!
    try {
        const response = await fetch('https://vote-api.aks-dev.cloudpirates.io/api/results')
        const data = await response.json()
        document.getElementById('loadCounter')!.removeAttribute('disabled')

        traceElement.innerHTML = response.headers.get('x-trace-id') ?? ''
        let content = 'Error on loading votes from backend'
        document.getElementById('cats')!.innerHTML = data.cats
        document.getElementById('dogs')!.innerHTML = data.dogs
        
        document.getElementById('percentage-cats')!.style.width = `${data.cats}%`
        document.getElementById('percentage-dogs')!.style.width = `${data.dogs}%`
        
        if (data.counter) {
            content = data.counter
            counterElement.classList.remove('text-danger')
        }
        else {
            counterElement.classList.add('text-danger')
            content = `???`
        }
        counterElement.innerHTML = `${content} Votes`
    }
    catch (error) {
        console.log(error)
    }
}

document.getElementById('loadCounter')?.addEventListener('click', loadCounter)
loadCounter()
